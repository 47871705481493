<template>
  <Dialog :mobile-bottom-sheet="true">
    <Form
      v-if="data.status"
      id="disable-order-alarm-form"
      submit-label="shop.orderAlarm.statusDialog.btn.disable"
      @cancel="dialogStore.closeDialog(DialogResponses.CANCEL)"
      @submit="onSubmit(data.status, data.orderAlarm)"
    >
      <div class="px-0 md:px-md pb-sm flex flex-col max-h-[92vh]">
        <div class="relative flex justify-center">
          <img
            src="@/assets/images/minilu/order-alarm-disable-all.svg"
            class="absolute -mt-xl"
          />
        </div>
        <div class="h-full overflow-auto text-center mt-[80px]">
          <p v-if="data.orderAlarm">
            {{ t('shop.orderAlarm.statusDialog.text.item.disable') }}
          </p>
          <p v-else>
            {{ t('shop.orderAlarm.statusDialog.text.disable') }}
          </p>
        </div>
      </div>
    </Form>
    <Form
      v-else
      id="disable-order-alarm-form"
      submit-label="shop.orderAlarm.statusDialog.btn.enable"
      @cancel="dialogStore.closeDialog(DialogResponses.CANCEL)"
      @submit="onSubmit(data.status, data.orderAlarm)"
    >
      <div class="px-0 md:px-md pb-sm flex flex-col max-h-[92vh]">
        <div class="flex justify-center relativ">
          <img
            src="@/assets/images/minilu/order-alarm-enable-all.svg"
            class="absolute -mt-xl"
          />
        </div>
        <div class="h-full overflow-auto text-center mt-[80px]">
          <p v-if="data.orderAlarm">
            {{ t('shop.orderAlarm.statusDialog.text.item.enable') }}
          </p>
          <p v-else>
            {{ t('shop.orderAlarm.statusDialog.text.enable') }}
          </p>
        </div>
      </div></Form
    ></Dialog
  >
</template>
<script setup lang="ts">
import Dialog from '@/components/dialog/components/dialogFrame/global/minilu/smallDialogFrame.vue';
import { DialogResponses, useDialogStore } from '@/stores/useDialogStore';
import Form from '@/components/formFields/localDialogForm/localDialogForm.vue';
import { useOrderAlarmDialog } from '../useEditOrderAlarmStatus';
import type { OrderAlarm } from '@/server/transformers/shop/orderAlarmTransformer';

const dialogStore = useDialogStore();
const data = dialogStore.getDialogData<{
  status: boolean;
  orderAlarm?: OrderAlarm;
}>();
const { onSubmit } = await useOrderAlarmDialog();
const { t } = useTrans();
</script>
<style lang="postcss" scoped></style>
